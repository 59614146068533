import React, { useEffect, useState } from 'react';

const useStateWithLocalStorage = (localStorageKey = 'wish') => {
  const [value, setValue] = React.useState(
    (typeof window !== 'undefined' &&
      JSON.parse(localStorage.getItem(localStorageKey))) ||
      []
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    }
  }, [value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
