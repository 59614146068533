import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const BtnWrapper = styled.button`
  position: absolute;
  z-index: 2;
  top: ${toRem(15)};
  right: ${toRem(15)};
  cursor: pointer;
`;
