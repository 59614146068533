import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHiking } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartBorder } from '@fortawesome/free-regular-svg-icons';

import { BtnWrapper } from './FavComponent.styles';

import { red } from 'theme/colors';

import useStateWithLocalStorage from '../../utils/wishList';

export const useLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true), []);
  return loaded;
};

const FavComponent = (props) => {
  //PROPS
  const { slug } = props;
  //PROPS

  const loaded = useLoaded();

  const [value, setValue] = useStateWithLocalStorage(
    props.amb ? 'wishA' : 'wish'
  );

  const onClick = (slug) => {
    if (typeof window !== 'undefined') {
      const localS =
        JSON.parse(localStorage.getItem(props.amb ? 'wishA' : 'wish')) || [];

      if (!localS.includes(slug)) {
        let newV = [...localS, slug];

        setValue(newV);
      } else {
        let arr = localS.filter(function (item) {
          return item !== slug;
        });

        setValue(arr);
      }
    }
  };

  return (
    <BtnWrapper onClick={() => onClick(slug)}>
      {loaded && value.includes(slug) ? (
        <FontAwesomeIcon icon={faHeart} color={red.default} />
      ) : (
        <FontAwesomeIcon icon={faHeartBorder} color={red.default} />
      )}
    </BtnWrapper>
  );
};

export default FavComponent;
